<template>
  <div>
    <div class="product-name">
      <div class="text-product-name">
        {{ productTradeName.toUpperCase() || productName.toUpperCase() }}
      </div>
    </div>
    <div class="general-info">
      <div class="qr-code">
        <qr-code :text="inventoryCode" :size="70" error-level="L"></qr-code>
      </div>
      <div class="product-cate-price">
        <div class="product-cate-price-box">
          <p class="text-imei-code">{{ inventoryCode.slice(-12) }}</p>
          <p class="text-imei-fifo">
            BC: #{{ formatDate(imeiFIFODate, DATE_FORMAT) }}#
          </p>
          <p class="text-product-barcode" v-if="barCode">
            {{ barCode.slice(-8) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { convertPrice } from '@/utils/common';
import VueQRCodeComponent from 'vue-qrcode-component';
import { formatDate } from '@/utils/date';

export default {
  props: {
    productTradeName: {
      value: '',
      required: true,
    },
    productName: {
      value: '',
      required: true,
    },
    brandName: {
      value: '',
      required: true,
    },
    categoryName: {
      value: '',
      required: true,
    },
    inventoryCode: {
      value: '',
      required: true,
    },
    imeiFIFODate: {
      value: '',
      required: true,
    },
    barCode: {
      value: '',
      required: true,
    },
  },
  data() {
    return {
      DATE_FORMAT: 'YYMMDD',
    };
  },
  methods: {
    convertPrice,
    formatDate,
  },
  components: {
    'qr-code': VueQRCodeComponent,
  },
  computed: {},
};
</script>

<style scoped>
.product-name {
  padding: 2mm 2mm 0 2mm;
  text-align: center;
  height: 10mm;
  display: flex;
  align-items: center;
  margin-top: 3px;
}
.text-product-name {
  line-height: 11px;
  color: #000;
  text-align: center;
  font-weight: 700;
  font-size: 11px;
  border-bottom: 1px solid #000;

  /* Giới hạn chiều cao hiển thị */
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Giới hạn 3 dòng */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 33px;
}
.general-info {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2px;
}
.qr-code {
  margin-top: 4px;
  margin-left: 16px;
  background-color: #fff;
  height: fit-content;
}
.product-cate-price {
  margin-left: 4px;
  padding-top: 5px;
  width: -webkit-fill-available;
}
.product-cate-price-box {
  border: 0.5px solid black !important;
  border-radius: 8px;
  padding: 8px;
  height: 100%;
  margin-right: 10px;
  min-width: 100px;
}

.product-cate-price-box p {
  margin-bottom: 0;
}
.text-imei-code {
  color: black;
  font-weight: 500;
  font-size: 13px;
  line-height: 0mm;
  padding-top: 5px;
}
.text-cate-name {
  color: black;
  font-weight: 500;
  font-size: 8px;
  line-height: 2mm;
}
.text-product-barcode {
  color: black;
  font-weight: 700;
  font-size: 15px;
  line-height: 2mm;
  padding-top: 2px;
  margin-top: 2px;
  /*border-top: 1px solid #000; */
}
.text-imei-fifo {
  color: black;
  font-weight: 700;
  font-size: 12px;
  padding: 7px 0px 10px;
}
</style>
